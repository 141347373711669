import '../styles/index.scss';

// const setViewportHeight = function(){
//     const doc = document.documentElement;
//     doc.style.setProperty('--app-height', window.innerHeight+'px');
// };

// setViewportHeight();

console.log('webpack starterkit');
